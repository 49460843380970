import { Button, Flex, Heading, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useCallback, useState } from 'react'

import Image from 'components/elements/Image'
import Link from 'components/elements/Link'
import type { NewService } from 'components/modules/Dashboard/MyServices/utils'

const IMAGE_WIDTH = { base: 240, sm: 780 }
const IMAGE_HEIGHT = 382

const FALLBACK_IMG_SRC = '/images/fallback.jpg'

const styles = {
  image: {
    backgroundColor: 'gray.100',
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    minW: { base: 'full', md: '50%' },
    maxW: { base: 'full', md: '50%' },
    minH: { base: '50%', md: 'full' },
    maxH: { base: '50%', md: 'full' },
  },
  service: {
    minH: { md: '383px' },
    direction: { base: 'column', md: 'row' },
  },
  serviceContent: {
    w: { base: 'full', md: '50%' },
    px: 10,
    alignItems: 'center',
  },
  serviceName: {
    mb: { base: 6, lg: 9 },
    fontSize: { base: '1.125rem', md: '1.875rem' },
    lineHeight: '1.66',
  },
  serviceDescription: {
    fontSize: { base: '0.875rem', md: '1rem' },
    lineHeight: '1.33',
    mt: '0 !important',
  },
  serviceIcon: {
    bgColor: { base: 'transparent', md: '#E2E2E2' },
    minW: { base: '76px', md: '120px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    boxSize: { base: 8, lg: 10 },
    marginInlineEnd: 0,
    minW: 10,
    color: 'gray333',
  },
  button: {
    w: '230px',
    bgColor: 'white',
  },
} as const satisfies SystemStyleInterpolation

interface NewServiceCardProps extends NewService {
  backgroundColor: string
}

const TAG = 'NewServiceCard'

const NewServiceCard: FunctionComponent<NewServiceCardProps> = ({ siteKey, url, backgroundColor }) => {
  const { t } = useTranslation()

  const [imgSrc, setImgSrc] = useState(`/images/${siteKey}-background.jpg`)

  const onError = useCallback(() => setImgSrc(FALLBACK_IMG_SRC), [])

  return (
    <Flex key={siteKey} {...styles.service} bgColor={backgroundColor}>
      <Image
        src={imgSrc}
        onError={onError}
        alt={t(`pages.dashboard.newServices.services.${siteKey}.label`)}
        role="presentation"
        priority={true}
        loading="eager"
        {...styles.image}
      />
      <Flex {...styles.serviceContent}>
        <Stack spacing={{ base: 6, lg: 10 }} py={{ base: 6, lg: 0 }}>
          <Heading as="h3" {...styles.serviceName}>
            {t(`pages.dashboard.newServices.services.${siteKey}.label`)}
          </Heading>
          <Text {...styles.serviceDescription}>{t(`pages.dashboard.newServices.services.${siteKey}.description`)}</Text>
          {url && (
            <Button variant="outline" as={Link} href={url} {...styles.button}>
              {t(`pages.dashboard.newServices.services.${siteKey}.buttonLabel`)}
            </Button>
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}

NewServiceCard.displayName = TAG

export default NewServiceCard
